(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/middlewares/kibana-mw.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/middlewares/kibana-mw.js');
"use strict";


const {
  isAnyOf,
  isRejected
} = RTK;
const {
  getLogger
} = svs.core.log;
const logger = getLogger('tipsen:store:mw:kibana');
const {
  actions: walletActions
} = svs.components.tipsen.wallet;
const {
  actions: engineActions
} = svs.components.tipsen.engine;
const {
  loadOpenedDraws,
  loadOpenedDrawsByEngine,
  loadDrawByProduct
} = svs.components.sport.drawsRedux.actions;

const isInterestingPendingAction = isAnyOf(loadOpenedDraws.pending, loadOpenedDrawsByEngine.pending);
const isInterestingFulfilledAction = isAnyOf(engineActions.generateSportPixCoupon.fulfilled, engineActions.generateSportPixCouponBoard.fulfilled, loadOpenedDraws.fulfilled, loadOpenedDrawsByEngine.fulfilled, loadDrawByProduct.fulfilled,
walletActions.setRetailerCommission, engineActions.selectWeek, engineActions.selectBetAmount, engineActions.setSportkryssFactorIndex, engineActions.setSportkryssNumber);
const isInterestingPayloadAction = isAnyOf(engineActions.generateSportPixCoupon.fulfilled, engineActions.generateSportPixCouponBoard.fulfilled, engineActions.selectWeek, engineActions.selectBetAmount, engineActions.setSportkryssFactorIndex, engineActions.setSportkryssNumber);

const loglevels = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
};
const AVOIDED_ERROR_LOGS = ['ConditionError', 'NoOutdatedDrawsError'];
const actionsToPreventLogging = 'fetchPlayerCompetitions/rejected'; 

const kibanaMw = () => next => action => {
  var _action$error$name, _action$error, _action$payload;
  const logMessage = [];
  let loglevel = loglevels.INFO;
  const errorName = (_action$error$name = (_action$error = action.error) === null || _action$error === void 0 ? void 0 : _action$error.name) !== null && _action$error$name !== void 0 ? _action$error$name : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.name;
  if (isRejected(action) && !AVOIDED_ERROR_LOGS.includes(errorName) && !action.type === actionsToPreventLogging) {
    var _action$meta$logLevel, _action$meta;
    loglevel = (_action$meta$logLevel = (_action$meta = action.meta) === null || _action$meta === void 0 ? void 0 : _action$meta.logLevelOverride) !== null && _action$meta$logLevel !== void 0 ? _action$meta$logLevel : loglevels.WARN;
    logMessage.push("Promise rejected: ".concat(action.type));
  } else if (isInterestingPendingAction(action)) {
    logMessage.push("Promise pending: ".concat(action.type));
  } else if (isInterestingFulfilledAction(action)) {
    logMessage.push("Promise fulfilled: ".concat(action.type));
  }
  if (logMessage.length) {
    var _action$meta2, _action$meta3, _action$error2;
    if ((_action$meta2 = action.meta) !== null && _action$meta2 !== void 0 && _action$meta2.arg) {
      logMessage.push("with args: ".concat(JSON.stringify(action.meta.arg)));
    }
    if ((_action$meta3 = action.meta) !== null && _action$meta3 !== void 0 && (_action$meta3 = _action$meta3.enhanchedData) !== null && _action$meta3 !== void 0 && _action$meta3.extraWagerData) {
      logMessage.push("with extraWagerData: ".concat(JSON.stringify(action.meta.enhanchedData.extraWagerData)));
    }
    if ((_action$error2 = action.error) !== null && _action$error2 !== void 0 && _action$error2.stack) {
      logMessage.push("Stack: ".concat(action.error.stack));
    }
    if (action.payload && isInterestingPayloadAction(action)) {
      logMessage.push("Payload: ".concat(JSON.stringify(action.payload)));
    }
    logger[loglevel](logMessage.join(' '));
  }
  next(action);
};
setGlobal('svs.components.tipsen.store.middlewares.kibanaMw', kibanaMw);

 })(window);