(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/helpers/normalize-wager.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/helpers/normalize-wager.js');
"use strict";

const _excluded = ["systemNum", "boardProps", "systemType"],
  _excluded2 = ["systemNum", "boardProps", "systemType"],
  _excluded3 = ["betProperties", "er1X2BoardData", "game1X2Boards", "scoreBoards", "erScoreBoardData", "expertenBoardData"],
  _excluded4 = ["currentAddonDrawNumber", "currentDrawNumber", "extraDescription", "productId", "systemType", "wager", "wins", "completedDraws"],
  _excluded5 = ["drawNum"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  products
} = svs.utils;
const {
  isAddonWagerByITSName
} = svs.components.sport.tipsenShared;
const {
  wagerSystemTypes
} = svs.components.tipsen.engine.constants;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  getOutcomeDistributionMatrix,
  expandScoreboardToSign,
  expandScoreboardOutcomePairsToSrowHex,
  expandOutcomePairsToSigns
} = svs.components.tipsen.expandedSystems;
const {
  compressSROWToHex
} = svs.components.tipsen.expandedSystems.utils;
const {
  getLogger
} = svs.core.log;
const {
  helpers
} = svs.components.tipsen.store;
const {
  utils
} = svs.components.tipsen.expandedSystems;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const logger = getLogger('store:normalize-wager');
const outcomeMap = {
  1: 0,
  X: 1,
  2: 2
};

const translateCompletedDrawProductId = productId => {
  const productName = productId.trim().toLowerCase();
  switch (productName) {
    case 'topptipsetextra':
      return 'topptipset';
    default:
      return productId;
  }
};
const ER_BOARDS = [wagerSystemTypes.REDUCERA_FRITT, wagerSystemTypes.OWNROWS];
const MSYS_LIKE_BOARDS = [wagerSystemTypes.MSYS, wagerSystemTypes.RSYS, wagerSystemTypes.USYS];
const EXPERTEN_BOARDS = [wagerSystemTypes.XPERTEN];
const convertDistributionMatrixToIndex = distributionMatrix => {
  const signs = distributionMatrix.map(event => {
    const convSigns = [];
    event.forEach((betValue, index) => {
      if (betValue > 0) {
        convSigns.push(index);
      }
    });
    return [convSigns];
  });
  return signs;
};
const convertboardDataToIndex = (board, eventTypeId) => {
  const outcomeCount = utils.getOutcomeCountForEvent(eventTypeId);
  if (!outcomeCount) {
    throw new Error("Unknown eventTypeId: ".concat(eventTypeId));
  }
  if (eventTypeId === EventTypeId.GOAL_COUNT) {
    const goalCountSigns = board.map(row => [row.map(value => parseInt(value, 10))]);
    return [goalCountSigns];
  }
  const mSigns = [];
  const signs = [];
  board.forEach(row => {
    mSigns.push(row.includes('M'));
    signs.push([row.replace('M', '').split('').map(n => utils.outcomeMap[n])]);
  });
  return [signs, mSigns];
};
const normalizeScoreBoard = _ref => {
  let {
    betBoards,
    betProperties = [],
    productBet,
    systemType
  } = _ref;
  const boards = betBoards.find(board => board.systemType.includes(systemType));
  const {
      systemNum,
      boardProps,
      systemType: _systemType
    } = boards,
    restOutcomes = _objectWithoutProperties(boards, _excluded);
  const gameBoard = _objectSpread({
    boards: {
      betProperties: betProperties || [],
      boardProps: boardProps || [],
      systemType,
      systemNum
    }
  }, productBet);
  const [signs, reduction] = expandScoreboardToSign(restOutcomes);
  gameBoard.boards.boardData = {
    signs,
    reduction
  };
  return gameBoard;
};
const normalizeBetBoard = (outcomes, uRow, productId) => {
  let uRowOutcomes = [];
  const eventTypeId = getDefinitionByProduct(productId).outcomes.eventTypeId;
  if (uRow) {
    uRowOutcomes = uRow.boardData.map(outcome => {
      var _outcomeMap$outcome;
      return (_outcomeMap$outcome = outcomeMap[outcome]) !== null && _outcomeMap$outcome !== void 0 ? _outcomeMap$outcome : null;
    });
  }
  const [signs, mSigns] = convertboardDataToIndex(outcomes.boardData, eventTypeId);
  return [signs, mSigns, uRowOutcomes];
};
const expandSystemToDistributionMatrix = _ref2 => {
  let {
    outcomeMatrix,
    mSigns,
    uRowOutcomes,
    productId,
    systemType,
    systemNum
  } = _ref2;
  const eventTypeId = getDefinitionByProduct(productId).outcomes.eventTypeId;
  const srows = helpers.getExpandedRows({
    signs: outcomeMatrix,
    mSigns,
    uSigns: uRowOutcomes
  }, systemType, systemNum, {
    eventTypeId
  });
  const distributionMatrix = getOutcomeDistributionMatrix(srows, {
    eventTypeId
  });
  return distributionMatrix;
};
const normalizeErBoards = _ref3 => {
  let {
    betBoards,
    productBet,
    betProperties = [],
    productId,
    systemType
  } = _ref3;
  const eventTypeId = getDefinitionByProduct(productId).outcomes.eventTypeId;
  const {
    boards
  } = betBoards;
  const systemNum = null;
  if (!systemType) {
    systemType = {
      true: wagerSystemTypes.SROW,
      false: wagerSystemTypes.MSYS
    }[betBoards.simpleRowsOnly];
  }
  const srows = helpers.getExpandedRows({
    boards
  }, systemType, systemNum, {
    eventTypeId
  });
  const distributionMatrix = getOutcomeDistributionMatrix(srows, {
    eventTypeId
  });
  const signs = convertDistributionMatrixToIndex(distributionMatrix);
  const gameBoard = _objectSpread({
    boards: {
      betProperties,
      boardProps: [],
      systemType,
      systemNum
    }
  }, productBet);
  gameBoard.boards.boardData = {
    signs,
    boards,
    simpleRowsOnly: betBoards.simpleRowsOnly,
    distribution: distributionMatrix
  };
  return gameBoard;
};
const normalizeErBoardsScore = _ref4 => {
  let {
    betBoards,
    betProperties = [],
    productBet,
    eventTypeId
  } = _ref4;
  const systemType = undefined;
  const systemNum = undefined;
  const srows = helpers.getExpandedRows({
    boards: betBoards.boards
  }, systemType, systemNum, {
    eventTypeId,
    shouldExpandERScoreBoardToSROWHex: true
  });
  const gameBoard = _objectSpread({
    boards: {
      betProperties: betProperties || []
    }
  }, productBet);
  gameBoard.boards.boardData = {
    boards: srows
  };
  return gameBoard;
};
const normalizeSROWBoards = _ref5 => {
  let {
    betBoards,
    betProperties,
    productBet,
    systemType,
    productId
  } = _ref5;
  const eventTypeId = getDefinitionByProduct(productId).outcomes.eventTypeId;
  const [boardDatas] = betBoards.reduce((_ref6, board) => {
    let [boardData, boardProps, systemTypes, systemNum] = _ref6;
    return [[...boardData, compressSROWToHex(board.boardData, {
      eventTypeId
    })], [...boardProps, board.boardProps], [...systemTypes, board.systemType], [...systemNum, board.systemNum]];
  }, [[], [], [], []]);
  return normalizeErBoards({
    betBoards: {
      boards: boardDatas
    },
    productBet,
    betProperties: (betProperties || []).concat(wagerSystemTypes.ENKELRADER),
    productId,
    systemType
  });
};
const normalizeMURBoards = _ref7 => {
  let {
    betBoards,
    betProperties = [],
    productBet,
    systemType,
    productId
  } = _ref7;
  const outcomes = betBoards.find(board => board.systemType.includes(systemType));
  const uRow = betBoards.find(board => board.systemType.includes(wagerSystemTypes.UROW));
  const {
      systemNum,
      boardProps,
      systemType: _systemType
    } = outcomes,
    restOutcomes = _objectWithoutProperties(outcomes, _excluded2);
  const [signs, mSigns, uSigns] = normalizeBetBoard(restOutcomes, uRow, productId, systemType, systemNum);
  const distribution = expandSystemToDistributionMatrix({
    outcomeMatrix: signs,
    mSigns,
    uRowOutcomes: uSigns,
    productId,
    systemType,
    systemNum
  });
  const gameBoard = _objectSpread({
    boards: {
      betProperties: betProperties || [],
      boardProps: boardProps || [],
      systemType,
      systemNum
    }
  }, productBet);
  gameBoard.boards.boardData = {
    signs,
    mSigns,
    uSigns,
    distribution
  };
  return gameBoard;
};
const normalizeScoreBoardWithOutcomePairs = _ref8 => {
  let {
    betBoards,
    betProperties = [],
    productBet,
    eventTypeId
  } = _ref8;
  const boards = expandScoreboardOutcomePairsToSrowHex(betBoards, eventTypeId);
  const gameBoard = _objectSpread({
    boards: {
      betProperties: betProperties || []
    }
  }, productBet);
  const [signs, reduction] = expandOutcomePairsToSigns(betBoards);
  gameBoard.boards.boardData = {
    boards,
    signs,
    reduction
  };
  return gameBoard;
};
const normalizeGameBoards = _ref9 => {
  let {
    productBet,
    systemType,
    productId
  } = _ref9;
  const {
      betProperties,
      er1X2BoardData,
      game1X2Boards,
      scoreBoards,
      erScoreBoardData,
      expertenBoardData
    } = productBet,
    restProductBet = _objectWithoutProperties(productBet, _excluded3);
  const eventTypeId = getDefinitionByProduct(productId).outcomes.eventTypeId;
  if (betProperties.some(betProperty => ER_BOARDS.includes(betProperty))) {
    if (eventTypeId === EventTypeId.RESULT) {
      return normalizeErBoardsScore({
        betBoards: erScoreBoardData,
        betProperties,
        productBet: restProductBet,
        productId,
        eventTypeId
      });
    }
    return normalizeErBoards({
      betBoards: er1X2BoardData || erScoreBoardData,
      betProperties,
      productBet: restProductBet,
      productId
    });
  }
  if (eventTypeId === EventTypeId.RESULT && betProperties.some(betProperty => EXPERTEN_BOARDS.includes(betProperty))) {
    return normalizeScoreBoardWithOutcomePairs({
      betBoards: expertenBoardData.eventResults,
      betProperties,
      productBet: restProductBet,
      eventTypeId
    });
  }

  if (eventTypeId === EventTypeId.RESULT) {
    return normalizeScoreBoard({
      betBoards: scoreBoards,
      betProperties,
      productBet: restProductBet,
      systemType
    });
  }

  if (MSYS_LIKE_BOARDS.includes(systemType)) {
    return normalizeMURBoards({
      betBoards: scoreBoards || game1X2Boards,
      betProperties,
      productBet: restProductBet,
      systemType,
      productId
    });
  }
  if (systemType === wagerSystemTypes.SROW) {
    return normalizeSROWBoards({
      betBoards: scoreBoards || game1X2Boards,
      productBet: restProductBet,
      systemType,
      productId
    });
  }
  throw new Error('Unsuported system wager');
};
const normalizeWager = (wagerData, competition) => {
  const {
      currentAddonDrawNumber,
      currentDrawNumber,
      extraDescription,
      productId,
      systemType,
      wager,
      wins,
      completedDraws
    } = wagerData,
    restWager = _objectWithoutProperties(wagerData, _excluded4);
  const productIdAsNumber = products.resolveProductId(productId);
  const currentDrawNumberInt = parseInt(currentDrawNumber, 10);
  let compType;
  let compDesc;
  if (competition) {
    compType = competition.getCompType();
    compDesc = competition.getCompetitionDesc();
  }
  let productBet;
  let addonBet;
  wager.bets.forEach(bet => {
    if (isAddonWagerByITSName(bet.productId)) {
      if (addonBet) {
        logger.warn("Addon bet is already set: ".concat(JSON.stringify(addonBet)));
      }
      addonBet = bet;
    } else {
      if (productBet) {
        logger.warn("Product bet is already set: ".concat(JSON.stringify(productBet)));
      }
      productBet = bet;
    }
  });
  delete productBet.productId;
  const {
      drawNum
    } = productBet,
    restProductBet = _objectWithoutProperties(productBet, _excluded5);


  const addonCompletedDrawNumbers = [];
  if (addonBet && completedDraws.length > 0) {
    completedDraws.forEach(draw => {
      if (products.resolveProductId(translateCompletedDrawProductId(draw.productId)) === 30) {
        addonCompletedDrawNumbers.push(draw.drawNumber);
      }
    });
  }
  const addonWin = [];
  const productWin = [];
  (wins || []).forEach(win => {
    if (isAddonWagerByITSName(win.productId)) {
      addonWin.push(_objectSpread(_objectSpread({}, win), {}, {
        winDiv: win.winDiv + 1,
        productId: products.resolveProductId(win.productId)
      }));
    } else {
      productWin.push(_objectSpread(_objectSpread({}, win), {}, {
        winDiv: win.winDiv + 1,
        productId: products.resolveProductId(win.productId)
      }));
    }
  });
  const normalizedCompletedDraws = completedDraws.map(draw => _objectSpread(_objectSpread({}, draw), {}, {
    productId: products.resolveProductId(translateCompletedDrawProductId(draw.productId))
  }));
  const normalizedWager = {
    productBet: _objectSpread({
      productId: productIdAsNumber,
      drawNumber: drawNum,
      wins: productWin,
      lastCompletedDrawNumber: currentDrawNumberInt,
      completedDraws: normalizedCompletedDraws,
      gameBoard: _objectSpread({}, normalizeGameBoards({
        productBet: restProductBet,
        systemType,
        productId: productIdAsNumber
      })),
      compType,
      compDesc
    }, restWager)
  };
  if (addonBet) {
    normalizedWager.addonBet = _objectSpread(_objectSpread({}, addonBet), {}, {
      wins: addonWin,
      addonCompletedDrawNumbers
    });
  }
  return normalizedWager;
};
setGlobal('svs.components.tipsen.store.helpers.normalizeWager', normalizeWager);

 })(window);