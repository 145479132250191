(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/endpoints/draw.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/endpoints/draw.js');
"use strict";

const {
  socket,
  log
} = svs.core;
const {
  products
} = svs.utils;
const {
  normalizeDrawForecast,
  normalizeDrawResult
} = svs.components.tipsen.store.helpers;
const {
  TWO_MINUTES
} = svs.components.tipsen.store.endpoints.constants.timeAsSeconds;
const {
  getDefinitionByProduct
} = svs.components.tipsen.engine.utils;
const {
  actions: drawSubscriptionActions
} = svs.components.tipsen.store.reducers.playerWager;
const logger = log.getLogger('store:api');
const resolveDrawForecastPath = _ref => {
  let {
    drawNumber,
    productId
  } = _ref;
  const productDrawName = products.getSpecificDrawName(productId);
  const drawForecastUrl = "/draw/1/".concat(productDrawName, "/draws/forecast/").concat(drawNumber);
  return drawForecastUrl;
};
const getDrawEndpoints = builder => ({
  getDrawForecastFetch: builder.query({
    query: _ref2 => {
      let {
        drawNumber,
        productId
      } = _ref2;
      return resolveDrawForecastPath({
        drawNumber,
        productId
      });
    },
    transformResponse: (res, meta, _ref3) => {
      let {
        productId
      } = _ref3;
      return normalizeDrawForecast(res.forecastResult, productId);
    },
    keepUnusedDataFor: TWO_MINUTES
  }),
  getDrawForecast: builder.query({
    queryFn: () => ({
      data: {}
    }),
    keepUnusedDataFor: TWO_MINUTES,
    onCacheEntryAdded: async (_ref4, _ref5) => {
      let {
        drawNumber,
        productId
      } = _ref4;
      let {
        updateCachedData,
        cacheDataLoaded,
        cacheEntryRemoved,
        dispatch
      } = _ref5;
      const context = {};
      const drawForecastUrl = resolveDrawForecastPath({
        drawNumber,
        productId
      });
      const onSocketUpdate = drawForecastMessage => {
        logger.info("[forecast]: Update, dId:".concat(drawNumber, " pId:").concat(productId));
        updateCachedData(draft => {
          if (drawForecastMessage) {
            Object.assign(draft, normalizeDrawForecast(drawForecastMessage.forecastResult, productId));
          }
        });
      };
      try {
        await cacheDataLoaded;
        dispatch(drawSubscriptionActions.addDrawForecastSubscription({
          productId,
          drawNumber
        }));
        logger.info("[forecast]: Subscribe, dId:".concat(drawNumber, " pId:").concat(productId));
        socket.subscribe(drawForecastUrl, onSocketUpdate, context, true, true, true);
        logger.debug('socket subscribed');
      } catch (err) {
        logger.info("[forecast]: cacheEntryRemoved resolved before cacheDataLoaded, dId:".concat(drawNumber, " pId:").concat(productId));
      }
      await cacheEntryRemoved;
      logger.debug('socket unsubscribed');
      logger.info("[forecast]: Unsubscribe, dId:".concat(drawNumber, " pId:").concat(productId));
      socket.unsubscribe(drawForecastUrl, onSocketUpdate);
      dispatch(drawSubscriptionActions.deleteDrawForecastSubscription({
        productId,
        drawNumber
      }));
    }
  }),
  getDrawResult: builder.query({
    query: _ref6 => {
      let {
        drawNumber,
        productId
      } = _ref6;
      const productIds = products.getSpecificDrawName(productId);
      const drawForecastUrl = "/draw/1/".concat(productIds, "/draws/").concat(drawNumber, "/result");
      return drawForecastUrl;
    },
    transformResponse: res => normalizeDrawResult(res.result),
    keepUnusedDataFor: TWO_MINUTES,
    extraOptions: {
      maxRetries: 3
    }
  }),
  getDrawsResults: builder.query({
    query: _ref7 => {
      let {
        productId
      } = _ref7;
      const definition = getDefinitionByProduct(productId);
      const drawsResultUrl = "/draw/1/".concat(definition.loadDrawsApiPath, "/draws/result");
      return drawsResultUrl;
    },
    transformResponse: response => {
      response.results = (response.results || [response.result]).filter(Boolean);
      delete response.result;
      return response;
    },
    keepUnusedDataFor: TWO_MINUTES
  })
});
setGlobal('svs.components.tipsen.store.endpoints.getDrawEndpoints', getDrawEndpoints);

 })(window);